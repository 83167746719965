.section__fixed {
  margin-top: 5rem;

  ul {
    margin: 0 0 1rem 0;
    padding: 0 0 0 1.25rem;

    li {
      display: list-item;
      margin: .5rem 0;
      list-style: disc;
    }
  }

  p {
    display: block;
    margin: .5rem 0;
    font-size: 1rem;
  }

  .centered {
      text-align: center;
  }
  .av-btn {
    margin: 40px auto 20px auto;
  }

  h2 {
      padding-bottom: 1rem;
  }

  @media screen and (max-width: 1023px) {
  }
}
