.section__lifestyle {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  width: 100%;

  .title {
    display: block;
    min-width: 24rem;
    max-width: 100%;

    @media screen and (max-width: 1023px) {
      min-width: 0;
    }
  }

  .subtitle {
    padding-bottom: 1rem;
  }
  .section__lifestyle-list {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    & .section__lifestyle-item:first-child {
      padding-left: 0 !important;
    }
  }

  .section__lifestyle-item {
    display: block;
    position: relative;
    max-width: 40%;
    padding: 0 2rem;
    text-align: justify;

    @media screen and (max-width: 1400px) {
      max-width: 45%;
    }
    @media screen and (max-width: 1400px) {
      max-width: 50%;
    }

    strong {
      font-weight: 700;
    }

    p {
      padding-bottom: .5rem;
    }
    .icon {
      display: block;

      padding: .5rem 0;
      font-size: 4.5rem;
      font-weight: 400;
      color: $color-primary;
      @media screen and (max-width: 767px) {
        font-size: 3rem;
        min-width: 4rem;
      }
    }

    .icon-img {
      display: block;

      padding: .5rem 0;
      margin: .5rem 0;
      height: 4.5rem;
      font-weight: 400;
      color: $color-primary;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      @media screen and (max-width: 767px) {
        height: 3rem;
        min-width: 4rem;
      }
    }

    .section__numbers-item-number {
      display: block;

      font-size: 4.5rem;
      font-weight: 400;
      font-family: $font-numbers;
      color: $color-primary;

      @media screen and (max-width: 767px) {
        min-width: 6rem;
        text-align: center;
      }
    }

    .section__numbers-item-strong {
      display: block;

      padding: .5rem 0;
      font-size: 2rem;
      font-weight: 500;
      line-height: 3rem;
      font-family: $font-numbers;
    }

    .section__number-item-strong-infinite {
      font-size: 3rem !important;
    }

    .section__numbers-item-row {
      @media screen and (max-width: 767px) {
        padding-left: 2rem;
      }
    }

    @media screen and (max-width: 1023px) {
      padding: 1rem .5rem;
      width: calc(100% / 2);
    }

    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      padding: .5rem 0;
      text-align: left;
    }
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
}
