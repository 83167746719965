.copy--link {
  cursor: pointer;
  /*
  border-bottom: solid;
  border-width: 1px;
  border-bottom-color: rgb(8, 164, 212);
  
  margin-left: 16px;
  */
}
