.preloader {
  display: inline-block;

  width: 3rem;
  height: 3rem;

  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../images/loader.png');
  background-size: contain;

  animation: spin 1s linear infinite;
}
