.footer__links {
  display: flex;
  justify-content: flex-end;

  .footer__links-item {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 1rem .75rem;
    text-indent: -9999em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .footer__links-item--hh {
    background-image: url('../images/hh-footer.svg');
  }

  .footer__links-item--hh-uz-footer {
    background-image: url('../images/hh.uz-footer.svg');
  }

  .footer__links-item--vk {
    background-image: url('../images/vk-footer.svg');
  }

  .footer__links-item--fb {
    background-image: url('../images/fb-footer.svg');
  }

  .footer__links-item--telegram {
    background-image: url('../images/telegram-footer.svg');
  }

  .footer__links-item--instagram {
    background-image: url('../images/instagram.svg');
  }

  .footer__links-item--linkedin {
    background-image: url('../images/linkedin-footer.svg');
  }
  .footer__links-item--dou {
    background-image: url('../images/dou-footer.svg');
  }
  .footer__links-item--djinni {
    background-image: url('../images/djinni-footer.svg');
  }

}
