.section__call-action {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column wrap;
  padding-top: 3rem;
  width: 100%;
  height: 40rem;
  @media screen and (max-width: 767px) {
    //padding-bottom: 3rem;
  }

  background: no-repeat center center;
  background-size: cover;
  background-image: url('../images/team.jpg');

  .title {
    color: white;
  }
  .section__call-action-row {
    display: flex;
    color: white;
    padding-top: 4rem;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding-top: 1rem;
    }

    .section__call-action-item {
      max-width: 25%;
      padding-right: 4rem;

      display: flex;
      flex-direction: column;
      //align-items: flex-end;
      justify-content: space-between;

      @media screen and (max-width: 1900px) {
          max-width: 40%;
      }
  
      @media screen and (max-width: 1200px) {
          max-width: 50%;
      }

      @media screen and (max-width: 767px) {
        max-width: 100%;
        margin-bottom: 2rem;
        padding-right: 2rem;
      }

      
    
      p {
        display: block;
        text-align: justify;
        float: none;
      }
      a {
        margin-top: 3rem;
        float: right;
        text-align: center;
        width: 12rem;
        @media screen and (max-width: 767px) {
          margin-top: 2rem;
        }
      }
    }


  }

  .section__portals-row {
    color: white;

    .job_portals__links {
      display: flex;
      justify-content: flex-start;
      flex-wrap:wrap;
      margin-top: 3rem;
      @media screen and (max-width: 767px) {
        margin-top:0;
      }

      .job_portals__links-row {
        display: flex;
        flex-wrap: wrap;
      }
      .hidden {
        display: none !important;
      }
    
      .portals-caption {
        height: 2.5rem;
        margin: 1rem 0.75rem;
        line-height: 2.5rem;
        @media screen and (max-width: 575px) {
          margin: .5rem .75rem;
        }
      }
      .job_portals__links-item {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        margin: 1rem .75rem;
        text-indent: -9999em;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        @media screen and (max-width: 575px) {
          margin: .5rem .75rem;
        }

      }
    
      .job_portals__links-item--hh {
        background-image: url('../images/hh.ru.svg');
      }
    
      .job_portals__links-item--hh-uz {
        background-image: url('../images/hh.uz.svg');
      }
    
      .job_portals__links-item--telegram {
        background-image: url('../images/telegram.svg');
      }
    
      .job_portals__links-item--linkedin {
        background-image: url('../images/linkedin.svg');
      }
      .job_portals__links-item--dou {
        background-image: url('../images/dou.svg');
      }
      .job_portals__links-item--djinni {
        background-image: url('../images/djinni.svg');
      }
    
    }

  }
  
  .section__office-buttons {
    margin: 2rem 0;

    .button {
      margin: .5rem;
    }

    @media screen and (max-width: 479px) {
      margin-left: 0;
    }
  }
}
