.video {
  display: block;

  &.video--screen {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    height: 100%;
    width: calc(100vh * 16 / 9);
    min-width: 100%;
    min-height: calc(100vw * 9 / 16);

    transform: translate(-50%, -50%);
    filter: brightness(80%);
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }
}
