@import "basic/fonts";
@import "basic/colors";
@import "basic/animations";
@import "basic/preloader";
@import "basic/reset";
@import "basic/body";
@import "basic/title";
@import "basic/icons";
@import "basic/text";
@import "basic/plot";
@import "basic/video";
@import "basic/helper";
@import "basic/gallery";
@import "basic/button";
@import "basic/input";
@import "basic/checkbox";
@import "basic/label";
@import "basic/list";
@import "basic/popover";
@import "basic/container";


@import "layout/header";
@import "layout/header/header__logo";
@import "layout/header/header__menu";
@import "layout/header/header__toggle";

@import "layout/section";
@import "layout/section/section__about";
@import "layout/section/section__technology";
@import "layout/section/section__benefits";
@import "layout/section/section__numbers";
@import "layout/section/section__lifestyle";
@import "layout/section/section__callaction";
@import "layout/section/section__staff";
@import "layout/section/section__work";
@import "layout/section/section__office";
@import "layout/section/section__quote";
@import "layout/section/section__vacancy";
@import "layout/section/section__job_application";
@import "layout/section/section__terms";
@import "layout/section/section__gallery";
@import "layout/section/section__contacts";
@import "layout/section/section__map";
@import "layout/section/section__portals";
@import "layout/section/section__fixed";
@import "layout/section/section__fixed_small";

@import "layout/upload";
@import "layout/upload/upload__drop";
@import "layout/upload/upload__error";
@import "layout/upload/upload__label";

@import "layout/_footer";
@import "layout/footer/footer__text";
@import "layout/footer/footer__title";
@import "layout/footer/footer__links";

@import "layout/_share.scss";
@import "layout/share/_copy_link.scss";
@import "layout/share/_share_button.scss";
@import "layout/share/_share_dropdown_content.scss";

.hidden {
    display: none !important;
  }
