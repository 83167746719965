.section__office {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column wrap;

  width: 100%;
  height: 37.5rem;

  background: no-repeat center center;
  background-size: cover;

  .section__office-buttons {
    margin: 2rem 0;

    .button {
      margin: .5rem;
    }

    @media screen and (max-width: 479px) {
      margin-left: 0;
    }
  }
}
