

.section__vacancy {
  display: block;
  position: relative;
  margin-top: 4rem;

  .vacancy-location {
    font-weight: 700;
    font-size: 1.5rem;
    color: $color-bright;
  }

  .section__vacancy-list {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem -1rem;
  }

  .section__vacancy-column {
    display: block;
    width: 50%;
    padding: 0 1rem 2rem 1rem;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    ul {
      margin: 0 0 1rem 0;
      padding: 0 0 0 1.25rem;

      li {
        display: list-item;
        margin: .5rem 0;
        list-style: disc;
      }
    }

    p {
      display: block;
      margin: .5rem 0;
      font-size: 1rem;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;	  
    }

    h4 {
      display: block;
      margin: .5rem 0;
      font-size: 1rem;
      font-weight: 600;
    }

    strong {
      font-weight: 600;
    }
  }

  .section__vacancy-more {
    display: none;
    flex-direction: row;
    margin: 0 -1rem;



    .button--more {
      display: block;
      margin-top: 1rem;
      position: absolute;
      bottom: .75rem;
      right: .75rem;

      @media screen and (max-width: 767px) {
        position: absolute;
        bottom: .75rem;
        right: .75rem;
        /*left: 50%;*/
        margin: 0;
        transform: translateX(-50%);
      }
    }



    .button--close {
      position: absolute;
      top: .75rem;
      right: .75rem;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 4rem;
    }
  }

  .section__vacancy-link {
    display: block;
    margin-top: 1.5rem;
    color: $color-primary;
    font-size: .8125rem;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      color: $color-primary-light;
    }
  }



  .section__vacancy-icon {
    display: block;
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
    width: 5rem;
    height: 5rem;

    @media screen and (max-width: 1023px) {
      top: 0;
      left: 50%;
      margin-left: -2.5rem;
      margin-top: 1rem;
    }
  }

  .section__vacancy-item {
    display: block;
    position: relative;
    width: calc(50% - 2rem);
    margin: 1rem;
    padding: 1rem 2rem;
    padding-left: 10rem;
    border: solid 1px gainsboro;
    transition: all .3s;

    .section__vacancy-short {
      strong {
        font-weight: 600;
      }
      p {
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;	  
      }
    }
    h4 {
		color: $color-primary-dry;
		@media screen and (max-width: 767px) {
			text-align: center;
		}

	}
    .text--tagline {
      /*margin-bottom: 1rem;*/

      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }

    &.section__vacancy-item--expand {
      width: 100%;
      box-shadow: 0 0 3px silver;

      .section__vacancy-short {
        display: none;
      }

      .section__vacancy-more {
        display: flex;
      }
    }

    @media screen and (max-width: 1023px) {
      width: 100%;
      padding-left: 2rem;
      padding-top: 7rem;
    }
  }
}
