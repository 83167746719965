.header__logo {
  display: flex;
  position: relative;
  z-index: 11;

  width: 14rem;
  height: 2rem;
  margin: 2rem 0;

  text-indent: -9999rem;

  background-image: url('../images/murano-logo-web.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (max-width: 1023px) {
    margin: 1.06rem 0 .5rem 0;
  }
}

.header--fixed .header__logo {
  margin: .5rem 0;
  
  @media screen and (max-width: 1023px) {
    margin: 1.06rem 0 .5rem 0;
  }

}
