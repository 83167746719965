.section__benefits {
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;

  .section__benefits-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 60%;
    height: 100%;

    background-image: url('../images/headline.jpg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;

    @media screen and (max-width: 1023px) {
      width: 100%;
      height: 70%;

      background-position: center top;
    }
  }

  .section__benefits-inner {
    display: block;

    width: 60%;
    margin-left: auto;
    padding: 3rem;

    background-color: white;
    border: solid 1px $color-primary;

    .text--heading {
      margin-top: 2.5rem;

      @media screen and (max-width: 479px) {
        display: none;
      }
    }

    @media screen and (max-width: 1023px) {
      width: 100%;
      margin-top: 30%;
      padding: 1.5rem 1rem;
    }
  }

  .section__benefits-about {
    /*max-width: 24rem;*/
  }

  .section__benefits-list {
    display: flex;
    flex-flow: row wrap;
    align-content: space-around;

    max-width: 45rem;
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .section__benefits-item {
    display: flex;
    align-self: center;

    width: calc(100% / 3 - 6rem);
    height: auto;

    margin: 1rem 3rem;

    opacity: .5;

    @media screen and (max-width: 767px) {
        width: calc(100% / 3 - 2rem);
    }

    @media screen and (max-width: 479px) {
      display: none;
    }
  }

  @media screen and (max-width: 1023px) {
    padding: 0;
  }
}
