.section__technology {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;

  width: calc(100% + 2rem);
  margin: 2rem -1rem 0;

  &.section__technology--animate {
    .section__technology-item {
        animation: fade .5s ease-in forwards;
        @for $i from 1 through 22 {
          &:nth-child(#{$i}n) {
            animation-delay: #{(calc($i/20) + .5)}s;
          }
        }
      }
  }

  .section__technology-item {
    display: block;
    opacity: 0;

    width: calc(100% / 6);
    min-height: 6.25rem;
    padding: .5rem 0;

    font-size: .875rem;
    text-align: center;
    vertical-align: top;

    .icon {
      display: block;
      margin: .25rem 0;
      font-size: 2rem;
    }

    .icon--dotnet {
      color: #0083c7;
    }

    .icon--java {
      color: #b91212;
    }

    .icon--cplusplus {
      color: #519a1c;
    }

    .icon--csharp {
      color: #0180c6;
    }

    .icon--visualstudio {
      color: #75008b;
    }

    .icon--javascript {
      color: #f2e24f;
    }

    .icon--msql-server {
      color: #d3494a;
    }

    .icon--nodejs-small {
      color: #83cd29;
    }

    .icon--angular {
      color: #c84432;
    }

    .icon--jquery {
      color: #015faa;
    }

    .icon--rails {
      color: #bc0502;
    }

    .icon--postgresql {
      color: #2c5b95;
    }

    .icon--tableau {
      color: #f36f20;
    }

    .icon--onedrive {
      color: #53bfff;
    }

    .icon--mongodb {
      color: #4faa41;
    }

    .icon--html5 {
      color: #e54d26;
    }

    .icon--android {
      color: #83cd29;
    }

    .icon--python {
      color: #417cac;
    }

    .icon--mysql {
      color: #2b6b7c;
    }

    .icon--salesforces {
      color: #0580c3;
    }

    .icon--typescript {
      color: #007ACC;
    }

    .icon--react {
      color: #61DAFB;
    }

    @media screen and (max-width: 767px) {
      width: calc(100% / 4);

      &:last-child {
        display: none;
      }
    }
  }
}
