  .section {
  display: block;
  position: relative;

  width: 100%;

  .subtitle {
    padding-top: .5rem;
    padding-bottom: 2rem;
  }
  &.section--screen {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
      padding-top: 13rem;
    }
    flex-direction: column;

    height: 100vh;
    min-height: 36rem;
    overflow: hidden;

    background-image: url('../images/cover.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &.section--row {
    display: flex;
    justify-content: space-between;

    margin-top: 3rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  &.section--padded {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  &.section--column {
    display: flex;
    flex-direction: column;

    margin-top: 3rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 1023px) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}
