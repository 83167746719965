.section__work {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;

  overflow: hidden;

  color: white;
  background: linear-gradient(to right, $color-primary-dark 0%, $color-primary 100%);

  .section__work-about {
    display: block;
    width: 45%;
    max-width: 40rem;

    .text--heading {
      margin-top: 2.5rem;
    }

    a {
      color: #08a4d4;
      text-decoration-line: none;
    }

    .switch--active {
      color: #fff;
    }

  
    @media screen and (max-width: 1023px) {
      width: 100%;
      max-width: 100%;
      padding-top: 3rem;
    }
  }


  .section__work-map {
    display: block;
    position: relative;

    width: 55%;
    max-width: 100%;
    height: 42rem;

    padding: 2rem;
    padding-right: 0;

    .section__work-map-label {
      cursor: default;
      transition: opacity .2s;
    }

    @media screen and (max-width: 1599px) {
      height: 30rem;
    }

    @media screen and (max-width: 1023px) {
      width: 100%;
      padding-left: 0;
    }

    @media screen and (max-width: 767px) {
      height: 24rem;
    }

    @media screen and (max-width: 479px) {
      height: 18rem;
    }
  }

  .section__work-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .section__work-item {
    display: block;

    margin: .5rem;
    margin-left: 0;

    font-size: 1.125rem;
    color: white;
    text-decoration: none;
    border-bottom: white solid 1px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $color-bright-light;
      border-color: $color-bright-light;
    }
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
