.section__staff {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 0;
  padding-bottom: 2rem;

  background: linear-gradient(to right, $color-bright 0%, $color-bright-light 100%);

  .section__staff-plot {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 80rem;
    height: 30rem;

    overflow: hidden;
    @media screen and (max-width: 1599px) {
      height: 24rem;
    }

    @media screen and (max-width: 1279px) {
      height: 16rem;
    }

    @media screen and (max-width: 767px) {
      height: auto;
    }
  }

  .staff_count_wrapper {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1023px) {
      flex-direction: row;
      width: 100%;
      padding: 1rem 0;
    }
  }

  .section__staff-count {
    @media screen and (max-width: 1023px) {
      float: right;
    }
    @media screen and (min-width: 1024px) {
      display: flex;
      flex-direction: column;
      width: 30rem;
      padding: 1rem;
    }

    @media screen and (max-width: 1660px) {
      width: 20rem;
    }
  
    max-width: 100%;
    
    text-align: center;

    color: white;

    .section__staff-count-strong {
      display: block;

      font-family: $font-numbers;
      font-size: 5rem;
      font-weight: 300;
      line-height: 1.2;

      @media screen and (max-width: 1660px) {
        font-size: 4rem;
      }
      @media screen and (max-width: 1380px) {
        font-size: 3rem;
      }
      @media screen and (max-width: 1024px) {
        font-size: 2rem;
      }

    }
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
  }
}
