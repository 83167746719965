.show {
  display: block !important;
}

.hidden {
  display: none;
}

.dropdown {
  display: inline-block;
}
