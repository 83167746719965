.section__portals {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;

  .text {
    max-width: 30rem;
    margin: 0 1rem 0 0;
    font-weight: 400;

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      text-align: center;
    }
  }

  .section__portals-link {
    display: block;
    width: 4rem;
    height: 2rem;
    margin: 0 .5rem;
    text-indent: -9999em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 810px) {
      width: 3rem;
    }

    &.section__portals-link--hh {
      background-image: url('../images/hh.ru.svg');
    }
    &.section__portals-link--hh-uz {
      background-image: url('../images/hh.uz.bl.svg');
    }

    &.section__portals-link--moikrug {
      background-image: url('../images/moikrug-logo.png');
    }

    &.section__portals-link--djinni {
      background-image: url('../images/djinni-logo.svg');
    }

    &.section__portals-link--dou {
      background-image: url('../images/dou.svg');
      background-size: cover;
    }

    &.section__portals-link--linkedin {
      background-image: url('../images/linkedin.svg');
    }

    &.section__portals-link--telegram {
      background-image: url('../images/telegram.svg');
    }

    @media screen and (max-width: 767px) {
      width: 8rem;
      height: 4rem;
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}
