.section__quote {
  display: block;
  position: relative;

  padding-top: 2rem;
  padding-bottom: 2rem;

  color: white;
  background-color: $color-primary;

  .section__quote-inner {
    display: block;
    position: relative;

    width: 50%;
    padding-left: 5rem;

    .icon {
      position: absolute;
      top: -.5rem;
      left: 0;

      font-size: 3.75rem;
      color: $color-primary-light;
      transform: rotate(180deg);
    }

    .text {
      font-size: 1.125rem;
      font-weight: 300;
    }

    .text--sign {
      font-size: .875rem;
    }

    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }
}
