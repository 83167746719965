.dropdown-content {
  top: 3rem;
  right: 4rem;
  display: none;
  position: absolute;
  /*background-color: #f1f1f1;*/
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(8, 164, 212, 0.30);
  padding-top: 12px;
  z-index: 1;

  @media screen and (max-width: 1023px) {
    left: .75rem;
    right: auto;
  }

  & a, & p {
    color: black;
    text-decoration: none;
    font-size: 100% !important;
  }

  & p {
    padding: 12px 16px;
    margin-top: 12px;
  }
/*
  & .copy--link {
    padding: 12px 16px 12px 0;
    margin-top: 12px;
  }
*/
  & img {
    width: 32px;
    height: 32px;
  }
  
  & .telegram {
	background-image: url('../images/telegram.svg');
  }
  & .facebook {
	background-image: url('../images/twitter.svg');
  }
  & .twitter {
	background-image: url('../images/fb.png');
  }
  & .vk {
	background-image: url('../images/vk32.png');
  }

  & .copy--link {
    background-image: url('../images/copy.svg');
  }
  & .first {
	margin-left: 16px !important;
  }

  & .last {
	margin-right: 16px !important;
  }

  & .link--share {
    display: inline-block;
    text-indent: -9999em;
    height: 32px;
	width: 32px;
	margin: 12px 8px 12px 8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
	}
}
