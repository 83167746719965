.list {
  margin: 0 0 1rem 0;
  padding: 0 0 0 1.25rem;

  .list__item {
    display: list-item;

    margin: .5rem 0;
    list-style: disc;
  }
}
