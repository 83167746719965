$color-primary: #08a4d4;
$color-primary-dark: #116496;
$color-primary-dry: #0874a5;
$color-primary-light: #2ac2f1;
$color-primary-hover: #167cad;
$color-primary-dim: #e7f6fb;
$color-bright: #d35904;
$color-bright-light: #f29313;
$color-ground: #333;
$color-ground-light: #8f8f8f;
