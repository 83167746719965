.section__job-application {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  color: white;
  text-align: center;

  width: 100%;
  height: 31.25rem;

  background-size: cover;
  background: url('../images/job-application-cover.jpg') no-repeat fixed center;

  .text--tagline {
    max-width: 100%;
    margin: 2rem 0;
    line-height: 1.75;
  }

  @media screen and (max-width: 1279px) {
    background-attachment: scroll;
  }
}
