﻿.button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;

  padding: .625rem 2.5rem;
  line-height: 1.5;

  font-size: .875rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;

  color: white;
  background-color: $color-primary;

  border: solid 2px $color-primary;
  border-radius: 2rem;

  transition: .3s all;

  &.button--outline {
    background-color: transparent;
    border-color: white;

    &:hover {
      color: black;
      background-color: white;
      border-color: white;
    }
  }

  &.button--highlite {
    background-color: $color-bright;
    border-color: $color-bright;
    &:hover {
      background-color: $color-bright-light;
      border-color: $color-bright-light;
    }
  }
  &.button--close {
    padding: 0;
    margin: 0;

    line-height: 1;
    font-size: 1.75rem;
    color: silver;
    background: none;
    border: none;

    &:hover {
      color: $color-primary;

      background: none;
      border: none;
    }
  }

  &:hover {
    border-color: $color-primary-light;
    background-color: $color-primary-light;
  }
}

.button:disabled {
  background-color: rgb(221, 221, 221);
  border-color: rgb(221,221,221);

  &:hover {
    background-color: rgb(221, 221, 221);
    border-color: rgb(221,221,221);
  }
}
