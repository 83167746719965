html {
}

body {
  font: 400 16px/1.4 $font-regular;
  background: white;

  &.body--overflow {
    @media screen and (max-width: 1023px) {
      position: fixed;
      overflow: hidden;
    }
  }

  @media screen and (max-device-width: 480px){
    -webkit-text-size-adjust: none;
  }
}

a {
  color: $color-primary;
}

@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}
