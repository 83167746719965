﻿.section__gallery {
  display: block;
  position: relative;

  color:white;
  background: linear-gradient(to right, $color-bright-light 0%, $color-bright 100%);
  .section__gallery-list {
    display: block;

    margin-top: 2rem;
    font-size: 0;
  }

  h2 {
    padding-top: 2rem;
    text-transform: uppercase;
  }
  .section__gallery-item {
    display: inline-block;
    position: relative;

    width: calc(100% / 5);
    padding-top: calc(100% / 6);

    font-size: 1rem;
    text-decoration: none;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &:after {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      cursor: zoom-in;

      font-size: 3rem;
      font-weight: 300;
      color: white;
      //background-color: rgba(0, 0, 0, .8);
    }

    &:hover:after {
      content: "+";
    }

    @media screen and (max-width: 1023px) {
      width: calc(100% / 3);
      padding-top: calc(100% / 3);

      &:nth-of-type(1n+13) {
        display: none;
      }
    }

    @media screen and (max-width: 767px) {
      width: calc(100% / 2);
      padding-top: calc(100% / 2);

      &:nth-of-type(1n+9) {
        display: none;
      }
    }
  }
}
