.footer__text {
  //width: 50%;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }

  a {
    text-decoration: none;
    font-size: .75rem;
  }
}
