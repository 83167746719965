@mixin plot-animate {
  [data-plot="path-main"] {
    animation: dash 2.5s linear forwards;
    animation-iteration-count: 1;
  }

  [data-plot="path-year"] {
    animation: dash .375s ease-in forwards;
    animation-iteration-count: 1;
  }

  @for $i from 1 through 19 {
    [data-plot="path-year"]:nth-child(#{$i}) {
      animation-delay: #{(0.1785*$i)}s;
    }
  }
}

.plot {
  [data-plot^="label-"] {
    opacity: 0;
    transition: opacity .5s;
  }

  [data-plot^="circle-"] {
    opacity: 0;
    transition: opacity .2s ease-in;

    &:hover {
      opacity: 1;
    }
  }

  [data-plot="path-main"] {
    stroke-dasharray: 1380;
    stroke-dashoffset: 1380;
  }

  [data-plot="path-year"] {
    &:nth-child(1) {
      stroke-dasharray: 28;
      stroke-dashoffset: 28;
    }
    &:nth-child(2) {
      stroke-dasharray: 51;
      stroke-dashoffset: 51;
    }
    &:nth-child(3) {
      stroke-dasharray: 111;
      stroke-dashoffset: 111;
    }
    &:nth-child(4) {
      stroke-dasharray: 137;
      stroke-dashoffset: 137;
    }
    &:nth-child(5) {
      stroke-dasharray: 147;
      stroke-dashoffset: 147;
    }
    &:nth-child(6) {
      stroke-dasharray: 176;
      stroke-dashoffset: 176;
    }
    &:nth-child(7) {
      stroke-dasharray: 190;
      stroke-dashoffset: 190;
    }
    &:nth-child(8) {
      stroke-dasharray: 232;
      stroke-dashoffset: 232;
    }
    &:nth-child(9) {
      stroke-dasharray: 267;
      stroke-dashoffset: 267;
    }
    &:nth-child(10) {
      stroke-dasharray: 272;
      stroke-dashoffset: 272;
    }
    &:nth-child(11) {
      stroke-dasharray: 286;
      stroke-dashoffset: 286;
    }
    &:nth-child(12) {
      stroke-dasharray: 306;
      stroke-dashoffset: 306;
    }
    &:nth-child(13) {
      stroke-dasharray: 337;
      stroke-dashoffset: 337;
    }
    &:nth-child(14) {
      stroke-dasharray: 383;
      stroke-dashoffset: 383;
    }
    &:nth-child(15) {
      stroke-dasharray: 388;
      stroke-dashoffset: 388;
    }
    &:nth-child(16) {
      stroke-dasharray: 399;
      stroke-dashoffset: 399;
    }
    &:nth-child(17) {
      stroke-dasharray: 404;
      stroke-dashoffset: 404;
    }
    &:nth-child(18) {
      stroke-dasharray: 409;
      stroke-dashoffset: 409;
    }
    &:nth-child(19) {
      stroke-dasharray: 432;
      stroke-dashoffset: 432;
    }

  }

  &.plot--animate {
    @include plot-animate;
  }

  @media screen and (max-width: 1023px) {
    @include plot-animate;
  }

  @supports (-ms-ime-align:auto) {
    [data-plot^="path-"] {
      stroke-dashoffset: 0 !important;
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    [data-plot^="path-"] {
      stroke-dashoffset: 0 !important;
    }
  }
}
