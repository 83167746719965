.share-button {
  padding: 0 0 0 5px;
  border: none;
  background-color: inherit;
  color: silver;
  line-height: 1;
  cursor: pointer;
  outline-style: none;
  position: absolute;
  top: .75rem;
  right: 4rem;
  background-image: url('../images/badges/share.svg');
  background-position-x: center;
  width: 28px;
  height: 28px;

  @media screen and (max-width: 1023px) {
      left: .75rem !important;
  }
}

div.noclose button.share-button {
  top: .75rem;
  right: .75rem;
}
