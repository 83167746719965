.helper {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 2rem;
  z-index: 5;

  height: 24px;
  width: 24px;
  margin-left: -12px;

  text-indent: 100%;
  white-space: nowrap;

  border: white 2px solid;
  border-left: 0;
  border-bottom: 0;

  transform: rotate(135deg);

  .header--expand + .section > & {
    display: none;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 14px;
    left: -16px;

    height: 24px;
    width: 24px;

    border: white 2px solid;
    border-bottom: 0;
    border-left: 0;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: -8px;

    height: 24px;
    width: 24px;

    border: white 2px solid;
    border-bottom: 0;
    border-left: 0;
  }
}
