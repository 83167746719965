.input {
  display: block;
  position: relative;

  .input__field {
    display: block;
    outline: none;
    width: 100%;
    padding: 1.5rem .5rem .5rem 0;
    font-size: .875rem;
    border: none;
    border-bottom: 1px solid silver;

    &.input__field--message {
      height: 5rem;
      resize: none;
      margin-top: 1.4rem;
    }
  }

  .input__label {
    display: block;
    position: absolute;
    top: 1.5rem;
    left: 0;
    pointer-events: none;
    font-size: .875rem;
    color: silver;
    transition: .2s ease all;
  }

  .input__field:focus ~ .input__label,
  .input__field:valid ~ .input__label {
    top: .25rem;
    font-size: .75rem;
  }

  .input__bar {
    display: block;
    position: relative;
    width: 100%;

    &:before,
    &:after {
      position: absolute;
      content: '';
      height: 1px;
      width: 0;
      bottom: 0;
      background-color: $color-primary-dark;
      transition: .4s ease all;
    }

    &:before {
      left: 50%;
    }

    &:after {
      right: 50%;
    }
  }

  .input__field:focus ~ .input__bar:before,
  .input__field:focus ~ .input__bar:after {
    width: 50%;
  }

  .input__error {
    display: none;
    position: absolute;
    top: 110%;
    right: 0;
    z-index: 2;
    font-size: .75rem;
    color: red;
  }

  &.input--error {
    .input__error {
      display: block;
    }

    .input__field {
      border-bottom-color: red;
    }
  }
}
