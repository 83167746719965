.header__toggle {
  display: none;
  position: relative;
  cursor: pointer;
  z-index: 11;

  width: 3.125rem;
  height: 3.125rem;
  margin: 2rem 0 2rem auto;

  .header__toggle-line {
    display: block;
    position: absolute;
    top: 2.25rem;
    right: .4375rem;

    width: 2.25rem;
		height: .25rem;

    transition: all .5s;
    background-color: white;
    border-radius: 3px;

    &:nth-child(2) {
      transform: translateY(-.8125rem);
    }

    &:last-child {
      transform: translateY(-1.625rem);
    }
  }

  @media screen and (max-width: 1023px) {
    display: flex;
    margin: .5rem 0 .5rem auto;
  }
}

.header--expand .header__toggle {
  .header__toggle-line {
    transform: translateY(-.8125rem) rotate(45deg);
  }

  .header__toggle-line:nth-child(2) {
    opacity: 0;
  }

  .header__toggle-line:last-child {
    transform: translateY(-.8125rem) rotate(-45deg);
  }
}

.header--fixed .header__toggle {
  margin-top: .5rem;
  margin-bottom: .5rem;
}
