.text {
  display: block;

  margin: .5rem 0;
  font-size: 1rem;

  &.text--heading {
    font-size: 1.25rem;
  }

  &.text--subtitle {
    font-size: 1rem;
    font-weight: 600;
  }

  &.text--tagline {
    font-size: 1.5rem;
    font-weight: 300;
  }

  &.text--dim {
    font-size: .75rem;
    color: $color-ground-light;
  }

  &.text--sign {
    position: relative;

    margin-top: 2rem;
    padding-top: 2rem;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;

      width: 3rem;
      height: 2px;

      background-color: white;
    }
  }
}
