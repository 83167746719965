.footer {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  justify-content: space-between;

  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: $color-ground;

  @media screen and (max-width: 767px) {
    flex-flow: column;
  }

  .footer_block {
    //width: 50%;

    .footer__contacts_title {
      color: white;
      text-transform: uppercase;
      font-size: 2rem;
      padding-bottom: 1.3rem;
    }

    .footer_row {
      display: flex;
      align-items: center;
      flex-wrap:nowrap;

      @media screen and (max-width: 1276px) {
        flex-wrap: wrap;
      }

      .contact_email {
        margin-right: 1.5rem;
        @media screen and (max-width: 647px) {
          padding-bottom: 1rem;
        }
        }
      a {
        text-decoration: none;
        color: white;
        font-size: 1.2rem;
        //margin-right: 1.5rem;
      }

      & a:first-child {
        margin-left: 0;
      }
    }
  }

  & .footer_block:first-child {
    padding-right:2rem;
  }
}