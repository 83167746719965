.upload .upload__error {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;

  font-size: .75rem;
  color: red;
}

.upload.input--error .upload__error {
  display: block;
}
