.title {
  display: block;
  position: relative;

  line-height: 1.25;
  font-size: 2.5rem;
  font-weight: 300;

  &.title--promo {
    display: flex;
    flex-direction: column;
    z-index: 3;

    max-width: 80%;
    font-size: 7.5rem;
    color: white;

    .text {
      display: block;

      margin-top: 1rem;
      font-size: 1.875rem;

      @media screen and (max-width: 767px) {
        font-size: 1.75rem;
      }

      @media screen and (max-width: 479px) {
        font-size: 1.5rem;
      }
    }

    @media screen and (max-width: 1525px) {
      font-size: 5.5rem;
    }

    @media screen and (max-width: 1330px) {
      font-size: 5.5rem;
    }

    @media screen and (max-width: 1140px) {
      font-size: 4.5rem;
    }

    @media screen and (max-width: 1023px) {
      max-width: 100%;
      line-height: 1.4;
    }

    @media screen and (max-width: 767px) {
      font-size: 3.5rem;
    }

    @media screen and (max-width: 636px) {
      font-size: 3rem;
    }

    @media screen and (max-width: 580px) {
      font-size: 2.5rem;
    }

    @media screen and (max-width: 479px) {
      font-size: 2rem;
    }
  }

  @media screen and (max-width: 479px) {
    font-size: 2rem;
  }
}
