.section__contacts {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  .section__contacts-title {
    width: 100%;
  }

  .section__contacts-info {
    width: 45%;
    margin-top: 2rem;

    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }
  .section__contacts-route {
    width: 45%;
    margin-top: 2rem;

    .text--subtitle {
      margin-top: 0;
    }

    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }

  .section__contacts-item {
    display: flex;
    position: relative;
    align-items: center;

    height: 3rem;
    padding-left: 3rem;

    .icon {
      display: flex;
      position: absolute;
      left: 0;
      top: 50%;
      align-items: center;
      justify-content: center;

      width: 2rem;
      height: 2rem;
      margin-top: -1rem;

      color: lightgrey;
      border: solid 1px lightgrey;
      border-radius: 50%;
    }
  }
}
