.checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;

    transition: all .3s;
  }

  & + label:before {
    content: '';
    display: block;
    float: left;
    width: 1.25rem;
    height: 1.25rem;

    margin-right: 1rem;
    vertical-align: text-top;

    background: white;
    border: solid 1px $color-primary;
    border-radius: 2px;
  }

  &:checked + label:before {
    background: $color-primary;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: .45rem;
    top: .175rem;

    width: .375rem;
    height: .7rem;

    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
