@mixin menu-item {
  display: flex;
  align-items: center;

  height: 100%;
  margin: 0;
  padding: .5rem;

  font-size: 1rem;
  color: white;
  text-decoration: none;

  border-bottom: solid 2px transparent;

  transition: .3s all;
}

.header__menu {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 10;

  height: 2rem;
  margin: 2rem 0 2rem 10rem;
  margin-left: 10rem;

  .header__menu-item {
    display: block;
    position: relative;
    cursor: default;

    height: 100%;
    margin: 0 1rem;

    &:hover {
      .header__menu-list {
        z-index: 11;
        opacity: 1;
        visibility: visible;

        transition: opacity .3s;
      }
    }
  }

  .button--action {
    background-color: $color-primary-light;
    border-color: $color-bright;
  }
  .header__menu-link {
    @include menu-item;
    text-wrap: nowrap;
    cursor: pointer;

    &:hover {
      border-color: $color-primary;
    }
  }

  .header__menu-label {
    @include menu-item;

    cursor: default;

    &:hover {
      border-color: $color-primary;
    }
  }

  .header__menu-list {
    display: block;
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;

    visibility: hidden;

    background-color: $color-primary-dry;
    border-top: solid 2px $color-primary;

    transition: none;

    .header__menu-link {
      padding: 1rem;
      font-size: .875rem;

      border-bottom: none;

      &:hover {
        background-color: $color-primary-hover;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }
}

.header--expand .header__menu {
  @media screen and (max-width: 1023px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: 0;
    padding: 7rem 0 2rem;

    background-color: $color-primary-dry;

    .header__menu-item {
      width: 100%;
      height: auto;
      margin: 0;

      &:hover {
        .header__menu-list {
          display: block;
        }
      }
    }

    .header__menu-link {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 1rem;
      font-size: 2rem;
      font-weight: 300;

      &:hover {
        border-color: transparent;
      }
    }

    .header__menu-label {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 1rem;
      font-size: 2rem;
      font-weight: 300;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;

        width: 5rem;
        height: 5rem;

        background-repeat: no-repeat;
        background-size: 2.5rem;
        background-position: center center;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjgzIDE2LjQybDkuMTcgOS4xNyA5LjE3LTkuMTcgMi44MyAyLjgzLTEyIDEyLTEyLTEyeiIgZmlsbD0iI2ZmZmZmZiIvPjxwYXRoIGQ9Ik0wLS43NWg0OHY0OGgtNDh6IiBmaWxsPSJub25lIiAvPjwvc3ZnPg==);
      }
    }

    .header__menu-list {
      position: relative;
      overflow: hidden;
      display: none;

      padding-left: 1rem;

      background-color: $color-primary-hover;
      border-top: none;

      .header__menu-link {
        margin: 0 1rem;
        font-size: 1.5rem;
      }
    }
  }
}

.header--fixed .header__menu {
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
}
