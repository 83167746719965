.section__terms {
  display: block;
  position: relative;

  h3 {
    font-size: 1.5rem;
    padding-top:1rem;
  }
  .section__terms-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 0 -1rem;
  }

  .section__terms-item {
    display: block;
    position: relative;

    width: 40%;
    margin: 1rem;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 5rem;
      height: 5rem;
      min-width: 5rem;
      min-height: 5rem;
      margin-right: 1rem;

      color: white;
      background-color: $color-primary;
      border: solid 4px $color-primary-dim;
      border-radius: 50%;
    }

    .text--tagline {
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 1023px) {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
