.section__map {
  display: flex;

  width: 100%;
  height: 25rem;
  margin-top: 2rem;

  .section__map-iframe {
    display: block;

    width: 100%;
    height: 100%;
    border: 0;
  }
}
