.header {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;

  background-color: transparent;

  &.header--expand {
    @media screen and (max-width: 1023px) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;

      height: auto;

      overflow-y: auto;
      overflow-x: hidden;

      background-color: $color-primary-dry;
    }
  }

  &.header--fixed {
    position: fixed;
    z-index: 20;

    background-color: $color-primary-dry;

    animation: slide .25s;
  }
}
