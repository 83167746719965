.upload__drop {
  display: block;
  position: relative;

  margin: .5rem 0;

  color: grey;
  font-size: .875rem;
  text-align: center;

  .upload__drop-file {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    cursor: pointer;

    width: 100%;
    height: 100%;
    opacity: 0;
    outline: none;
  }

  .upload__drop-text {
    display: block;

    width: 100%;
    padding-right: 2.5rem;
    line-height: 1.25rem;

    text-align: center;
    color: gray;
  }

  .upload__drop-button {
    display: block;
    position: absolute;
    right: .5rem;
    top: .5rem;
    cursor: pointer;

    height: 2rem;
    width: 2rem;

    line-height: 2rem;
    font-family: 'murano' !important;

    color: white;

    border-radius: 50%;

    &.upload__drop-button--insert {
      @extend .icon--add;

      background-color: $color-primary;
    }

    &.upload__drop-button--cancel {
      @extend .icon--close;

      display: none;
      z-index: 15;

      color: slategrey;
      background-color: gainsboro;
    }
  }

  .upload__drop-area {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 3rem;
    padding: .5rem;

    border: dashed 1px silver;

    &.upload__drop-area--over {
      border: dashed 1px $color-primary;
    }

    &.upload__drop-area--selected {
      border: solid 1px silver;

      .upload__drop-text {
        text-align: left;
        color: black;
      }

      .upload__drop-button--insert {
        display: none;
      }

      .upload__drop-button--cancel {
        display: block;
      }
    }
  }
}
