@keyframes dash {
  to {
    stroke-dashoffset: 0px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    margin-top: -100%;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
