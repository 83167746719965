@mixin popover-fullscreen {
  @media screen and (max-width: 1023px) {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    overflow-y: auto;
    overflow-x: hidden;

    border-radius: 0;
  }
}

.popover {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 30;

  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem 0;

  background-color: rgba(0, 0, 0, .5);

  .popover__box {
    @include popover-fullscreen;

    display: flex;
    position: relative;
    flex-direction: column;

    height: auto;
    width: auto;

    margin: auto;

    padding: 2rem 3rem;

    background-color: white;
    border-radius: .25rem;

    transition: all .3s;

    .button--close {
      position: absolute;
      right: .75rem;
      top: .75rem;
    }
  }

  .popover__box-title {
    font-size: 1.5rem;
    font-weight: 300;

    text-align: center;
  }

  .popover__box-item {
    display: block;

    width: 28rem;
    max-width: 100%;
    margin: .5rem auto;

    .button {
      width: 100%;
      margin-top: 1rem;
    }

    .input__field {
      width: 28rem;
      max-width: 100%;
    }
  }

  .popover__box-status {
    display: none;

    font-size: 1.25rem;
    font-weight: 300;
    color: whitesmoke;
    text-align: center;

    .icon {
      display: block;

      padding-bottom: 1rem;
      font-size: 4rem;
    }

    .preloader {
      display: block;

      margin: 0 auto 2rem;
    }
  }

  &.popover--visible {
    display: flex;
  }

  &.popover--fail,
  &.popover--success,
  &.popover--process {
    .popover__box {
      @include popover-fullscreen;

      justify-content: center;
      width: 28.75rem;
      height: 21rem;

      & > * {
        display: none;
      }
    }
  }

  &.popover--fail {
    .popover__box {
      background-color: $color-bright;

      .popover__box-status--fail {
        display: block;
      }
    }
  }

  &.popover--process {
    .popover__box {
      background-color: $color-primary-dry;

      .popover__box-status--process {
        display: block;
      }
    }
  }

  &.popover--success {
    .popover__box {
      background-color: $color-bright-light;

      .popover__box-status--success {
        display: block;
      }
    }
  }
}
