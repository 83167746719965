@font-face {
  font-family: 'murano';
  src:  url('../fonts/murano/murano.eot');
  src:  url('../fonts/murano/murano.eot') format('embedded-opentype'),
    url('../fonts/murano/murano.ttf') format('truetype'),
    url('../fonts/murano/murano.woff') format('woff'),
    url('../fonts/murano/murano.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon--"], [class*=" icon--"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'murano' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--add:before {
  content: "\e91b";
}
.icon--cplusplus:before {
  content: "\e906";
  color: #9c033a;
}
.icon--english:before {
  content: "\e919";
}
.icon--tennis:before {
  content: "\e91a";
}
.icon--typescript:before {
  content: "\e917";
}
.icon--react:before {
  content: "\e918";
}
.icon--check:before {
  content: "\e916";
}
.icon--close:before {
  content: "\e915";
}
.icon--fruit:before {
  content: "\e902";
}
.icon--book:before {
  content: "\e903";
}
.icon--computer:before {
  content: "\e904";
}
.icon--drink:before {
  content: "\e905";
}
.icon--envelope:before {
  content: "\e908";
}
.icon--food:before {
  content: "\e909";
}
.icon--friends:before {
  content: "\e90a";
}
.icon--gamepad:before {
  content: "\e90b";
}
.icon--hh:before {
  content: "\e90c";
}
.icon--medicine:before {
  content: "\e90d";
}
.icon--meetup:before {
  content: "\e90e";
}
.icon--people:before {
  content: "\e910";
}
.icon--phone:before {
  content: "\e911";
}
.icon--pin:before {
  content: "\e912";
}
.icon--relocation:before {
  content: "\e913";
}
.icon--vk:before {
  content: "\e914";
}
.icon--quote:before {
  content: "\e90f";
}
.icon--tableau:before {
  content: "\e900";
}
.icon--salesforces:before {
  content: "\e901";
}
.icon--csharp:before {
  content: "\e907";
  color: #68217a;
}
.icon--bitbucket:before {
  content: "\e603";
}
.icon--github-alt:before {
  content: "\e608";
}
.icon--github-badge:before {
  content: "\e609";
}
.icon--github:before {
  content: "\e60a";
}
.icon--github-full:before {
  content: "\e617";
}
.icon--java:before {
  content: "\e638";
}
.icon--ruby:before {
  content: "\e639";
}
.icon--python:before {
  content: "\e63c";
}
.icon--rails:before {
  content: "\e63b";
}
.icon--django:before {
  content: "\e61d";
}
.icon--php:before {
  content: "\e63d";
}
.icon--mysql:before {
  content: "\e604";
}
.icon--streamline:before {
  content: "\e605";
}
.icon--database:before {
  content: "\e606";
}
.icon--javascript:before {
  content: "\e64e";
}
.icon--angular:before {
  content: "\e653";
}
.icon--jquery:before {
  content: "\e650";
}
.icon--jquery-ui:before {
  content: "\e654";
}
.icon--ember:before {
  content: "\e61b";
}
.icon--dojo:before {
  content: "\e61c";
}
.icon--nodejs:before {
  content: "\e619";
}
.icon--nodejs-small:before {
  content: "\e618";
}
.icon--javascript-shield:before {
  content: "\e64f";
}
.icon--sass:before {
  content: "\e64b";
}
.icon--css3-full:before {
  content: "\e64a";
}
.icon--css3:before {
  content: "\e649";
}
.icon--html5:before {
  content: "\e636";
}
.icon--joomla:before {
  content: "\e641";
}
.icon--drupal:before {
  content: "\e642";
}
.icon--wordpress:before {
  content: "\e60b";
}
.icon--npm:before {
  content: "\e61e";
}
.icon--bing-small:before {
  content: "\e600";
}
.icon--windows:before {
  content: "\e60f";
}
.icon--linux:before {
  content: "\e612";
}
.icon--ubuntu:before {
  content: "\e63a";
}
.icon--android:before {
  content: "\e60e";
}
.icon--apple:before {
  content: "\e611";
}
.icon--appstore:before {
  content: "\e613";
}
.icon--blackberry:before {
  content: "\e623";
}
.icon--netmagazine:before {
  content: "\e62e";
}
.icon--dropbox:before {
  content: "\e607";
}
.icon--google-drive:before {
  content: "\e631";
}
.icon--visualstudio:before {
  content: "\e60c";
}
.icon--unity-small:before {
  content: "\e621";
}
.icon--less:before {
  content: "\e658";
}
.icon--atlassian:before {
  content: "\e65b";
}
.icon--jira:before {
  content: "\e65c";
}
.icon--google-analytics:before {
  content: "\e660";
}
.icon--onedrive:before {
  content: "\e662";
}
.icon--gulp:before {
  content: "\e663";
}
.icon--w3c:before {
  content: "\e66c";
}
.icon--postgresql:before {
  content: "\e66e";
}
.icon--webplatform:before {
  content: "\e66f";
}
.icon--uikit:before {
  content: "\e673";
}
.icon--groovy:before {
  content: "\e675";
}
.icon--nginx:before {
  content: "\e676";
}
.icon--dotnet:before {
  content: "\e67f";
}
.icon--javascript-badge:before {
  content: "\e681";
}
.icon--msql-server:before {
  content: "\e67c";
}
.icon--creativecommons:before {
  content: "\e689";
}
.icon--mitlicence:before {
  content: "\e68b";
  color: #211915;
}
.icon--ruby-rough:before {
  content: "\e691";
}
.icon--terminal:before {
  content: "\e695";
}
.icon--code:before {
  content: "\e696";
}
.icon--aptana:before {
  content: "\e699";
}
.icon--mongodb:before {
  content: "\e6a4";
}

.icon--image-benefits {
  background-image: url(../images/Icons/benefits.svg);
}

.icon--image-meetups {
  background-image: url(../images/Icons/meetups.svg);
}

.icon--image-events {
  background-image: url(../images/Icons/events.svg);
}

.icon--image-career {
  background-image: url(../images/Icons/career.svg);
}