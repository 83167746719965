.container {
  width: 100%;
  padding: 0 5rem;

  @media screen and (max-width: 1599px) {
    padding: 0 3rem;
  }

  @media screen and (max-width: 1023px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 767px) {
    padding: 0 1rem;
  }
}
