.section__about {
  display: block;
  position: relative;
  margin-top: 4rem;
  width: 60%;
  padding: 0 3rem;

  &.section__about--excerpt {
    width: 24rem;
    max-width: 100%;
    padding: 0;

    @media screen and (max-width: 1023px) {
      width: 100%;
      margin-top: 3rem;
    }
  }

  @media screen and (max-width: 1023px) {
    width: 100%;
    padding: 0;
    margin-top: 2rem;
  }
}
